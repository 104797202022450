import { isNil } from 'lodash';
import { DateTime, Duration } from 'luxon';

/**
 * A helper type that recursively converts DateTime and Duration types to string types to represent the unparsed
 * date/time strings returned by the API.
 *
 * @example
 * interface Foo {
 *   a: number;
 *   b: DateTime;
 * }
 * RawDateTimes<Foo> == {
 *   a: number;
 *   b: string;
 * }
 */
export type RawDateTimes<T> = T extends DateTime | Duration
  ? string
  : T extends object
  ? { [K in keyof T]: RawDateTimes<T[K]> }
  : T extends Array<infer U>
  ? Array<RawDateTimes<U>>
  : T;

/**
 * Parse a date time value from a string.
 * @param value The string to parse
 * @return The parsed {@link DateTime} object
 */
export function parseDateTime(value: string): DateTime {
  return DateTime.fromISO(value);
}

/**
 * Parse a duration value from a string.
 * @param value The string to parse
 * @return The parsed {@link Duration} object
 */
export function parseDuration(value: string): Duration {
  return Duration.fromISO(value);
}

export function parseToPercentageValue(value?: number | null) {
  return !isNil(value) ? value * 100 : null;
}
