import { PartialCaseCalculationsKeys } from './priceTargets.mapper.types';

export const baseCaseKeys: PartialCaseCalculationsKeys = [
  'baseCase',
  'targetDate',
  'realizationProbability',
  'metric',
  'metricYear',
  'metricValue',
  'multiple',
];

export const bearCaseKeys: PartialCaseCalculationsKeys = [
  'bearCase',
  'bearTargetDate',
  'bearRealizationProbability',
  'bearMetric',
  'bearMetricYear',
  'bearMetricValue',
  'bearMultiple',
];

export const bullCaseKeys: PartialCaseCalculationsKeys = [
  'bullCase',
  'bullTargetDate',
  'bullRealizationProbability',
  'bullMetric',
  'bullMetricYear',
  'bullMetricValue',
  'bullMultiple',
];
