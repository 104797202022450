import { CompanyGroupingStateType } from '../../../model/company';
import { PriceTargetCalculations, RawPriceTargetCalculations } from '../../../model/priceTargetCalculations';
import { parseDateTime, parseDuration, parseToPercentageValue } from '../../parse';
import { baseCaseKeys, bearCaseKeys, bullCaseKeys } from './priceTargets.mapper.constants';
import { GetCaseCalculations, PartialCaseCalculations } from './priceTargets.mapper.types';

export const getCompanyStateTypeGroup = (
  companyStateType: string,
  direction?: string | null,
): CompanyGroupingStateType => {
  if (companyStateType === 'IN_PORTFOLIO') {
    return direction === 'LONG' ? CompanyGroupingStateType.PORTFOLIO_LONG : CompanyGroupingStateType.PORTFOLIO_SHORT;
  }

  if (companyStateType === 'FOCUS_NEUTRAL') {
    return CompanyGroupingStateType.FOCUS_NEUTRAL;
  }

  if (companyStateType === 'FOCUS_DIRECTIONAL') {
    return direction === 'LONG' ? CompanyGroupingStateType.FOCUS_LONG : CompanyGroupingStateType.FOCUS_SHORT;
  }

  if (companyStateType === 'FOCUS_UNASSIGNED') {
    return CompanyGroupingStateType.FOCUS_UNASSIGNED;
  }

  return CompanyGroupingStateType.OTHER;
};

export const getCaseCalculations: GetCaseCalculations = ({ caseKeys, companyPriceTarget, caseCalculations }) => {
  const calculations: PartialCaseCalculations = {
    caseValue: null,
    targetDate: null,
    realizationProbability: null,
    metric: null,
    metricYear: null,
    metricValue: null,
    multiple: null,
  };

  if (companyPriceTarget) {
    calculations.caseValue = companyPriceTarget[caseKeys[0]] || null;
    const targetDate = companyPriceTarget[caseKeys[1]];
    calculations.targetDate = targetDate ? parseDateTime(targetDate) : null;
    calculations.realizationProbability = parseToPercentageValue(companyPriceTarget[caseKeys[2]]);
    calculations.metric = companyPriceTarget[caseKeys[3]] || null;
    calculations.metricYear = companyPriceTarget[caseKeys[4]] || null;
    calculations.metricValue = companyPriceTarget[caseKeys[5]] || null;
    calculations.multiple = companyPriceTarget[caseKeys[6]] || null;
  }

  return {
    priceTargetReturn: parseToPercentageValue(caseCalculations?.priceTargetReturn),
    irr: parseToPercentageValue(caseCalculations?.irr),
    yearRollingPT: caseCalculations?.yearRollingPT || null,
    yearRollingReturn: parseToPercentageValue(caseCalculations?.yearRollingReturn),
    ...calculations,
  };
};

export function fromServerMap(rawData: RawPriceTargetCalculations[]): PriceTargetCalculations[] {
  return rawData.map(
    ({
      company,
      companyStateType,
      direction,
      exposure,
      rank,
      price,
      priceTime,
      skew,
      upsideOverDownside,
      daysOld,
      companyPriceTarget,
      baseCaseCalculations,
      bearCaseCalculations,
      bullCaseCalculations,
    }) => ({
      companyId: company.id,
      company: {
        id: company.id,
        ticker: company.ticker,
        name: company.name,
        currency: company.currency,
        suspended: company.suspended,
      },
      state: companyStateType,
      companyStateType,
      companyGroupingStateType: getCompanyStateTypeGroup(companyStateType, direction),
      direction,
      exposure,
      rank,
      price,
      priceTime: priceTime ? parseDateTime(priceTime) : null,
      skew,
      upsideOverDownside,
      daysOld,
      companyPriceTarget: {
        id: companyPriceTarget?.id || null,
        time: companyPriceTarget ? parseDateTime(companyPriceTarget.time) : null,
        createdBy: companyPriceTarget?.createdBy || null,
        valid: companyPriceTarget?.valid || null,
        duration: companyPriceTarget?.duration ? parseDuration(companyPriceTarget?.duration) : null,
      },
      baseCaseCalculations: getCaseCalculations({
        caseKeys: baseCaseKeys,
        companyPriceTarget,
        caseCalculations: baseCaseCalculations,
      }),
      bearCaseCalculations: getCaseCalculations({
        caseKeys: bearCaseKeys,
        companyPriceTarget,
        caseCalculations: bearCaseCalculations,
      }),
      bullCaseCalculations: getCaseCalculations({
        caseKeys: bullCaseKeys,
        companyPriceTarget,
        caseCalculations: bullCaseCalculations,
      }),
    }),
  );
}
